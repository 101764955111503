import React, {useEffect} from 'react';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';
import {DynamicStep, DynamicStepComponentProps} from '../../StepsManager/Components/DynamicStep';
import {StepHeader} from '../../StepsManager/Components/StepHeader';
import {useControllerProps} from '../../../Widget/ControllerContext';
import {ContactAndAddressSummary} from '../../../ContactAndAddressSummary/ContactAndAddressSummary';
import {StatesButtonStates} from 'wix-ui-tpa';
import {usePaymentsApi} from '../../../WithPaymentsApi/WithPaymentsApi';
import {useBillingData, withBillingData} from './WithBillingData/WithBillingData';
import {PaymentWidgetWrapper} from './PaymentWidgetWrapper';
import {cashierWidgetEwalletPaymentMethod} from '../../../../../domain/utils/cashier.utils';
import {useFedopsLogger} from '@wix/yoshi-flow-editor';
import {ADD_NEW_ADDRESS_ID, FedopsInteractions} from '../../../constants';
import {StepImplementationProps, StepState} from '../../../../../types/app.types';
import {BillingAddressTitle} from './BillingAddressTitle/BillingAddressTitle';
import {BillingDetails} from './BillingDetails/BillingDetails';
import {useMemberDetailsData, withMemberDetailsData} from '../../../MemberDetails/WithMemberDetailsData';
import {CheckoutModel} from '../../../../../domain/models/Checkout.model';
import {DetailsFormButtons} from '../../StepsManager/Components/DetailsFormButtons/DetailsFormButtons';
import {useContinueButtonState} from '../../StepsManager/Components/DetailsFormButtons/UseContinueButtonState';

export enum PaymentStepDataHook {
  root = 'PaymentStep.root',
  header = 'PaymentStep.header',
  collapsed = 'PaymentStep.collapsed',
  open = 'PaymentStep.open',
  empty = 'PaymentStep.empty',
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const InternalPaymentStep = ({index}: DynamicStepComponentProps) => {
  const localeKeys = useLocaleKeys();
  const {paymentsApi, activePaymentId} = usePaymentsApi();

  const {
    checkoutStore: {checkout, setPaymentAndBillingDetails, isShippingFlow},
    formsStore: {areFormsLoaded},
    stepsManagerStore: {stepsList},
    memberStore: {isMember},
  } = useControllerProps();

  const fedops = useFedopsLogger();
  const stepState = stepsList[index].state;

  const isEwalletPaymentMethod = activePaymentId ? cashierWidgetEwalletPaymentMethod.includes(activePaymentId) : false;
  const shouldShowBillingForm = !(isEwalletPaymentMethod ? checkout.hasShippableItems : false);

  const {
    isFormValid,
    initForm,
    getBillingFormDataForSubmit,
    billingSameAsShipping,
    contactCountry,
    isShippingValidForBilling,
  } = useBillingData();

  const {selectedAddressesServiceId, resetMemberDetailsState, isAddNewChecked, isSetAsDefaultChecked} =
    useMemberDetailsData();

  const {continueButtonState, isContinueButtonDisabled, setContinueButtonState, setIsContinueButtonDisabled} =
    useContinueButtonState();

  useEffect(
    () => {
      if (stepState === StepState.OPEN) {
        void paymentsApi?.expand();
        setContinueButtonState(StatesButtonStates.IDLE);
        setIsContinueButtonDisabled(!areFormsLoaded);
        initForm();
        if (isMember) {
          resetMemberDetailsState();
        }
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [stepState]
  );

  const validateAndSubmit = async () => {
    setContinueButtonState(StatesButtonStates.IN_PROGRESS);
    setIsContinueButtonDisabled(true);
    const isBillingFormValid = shouldShowBillingForm ? await isFormValid() : true;
    const setBillingSameAsShipping =
      !shouldShowBillingForm || (isShippingFlow && billingSameAsShipping && isShippingValidForBilling);

    fedops.interactionStarted(FedopsInteractions.ValidatePaymentInteraction);
    const {isValid: isPaymentValid} = (await paymentsApi?.validate()) ?? /* istanbul ignore next */ {};
    fedops.interactionEnded(FedopsInteractions.ValidatePaymentInteraction);

    if (!isPaymentValid || !isBillingFormValid) {
      setContinueButtonState(StatesButtonStates.IDLE);
      setIsContinueButtonDisabled(false);
      return;
    }

    void setPaymentAndBillingDetails({
      ...getBillingFormDataForSubmit(),
      addressesServiceId: isAddNewChecked ? ADD_NEW_ADDRESS_ID : selectedAddressesServiceId,
      setBillingSameAsShipping,
      setAsDefault: isSetAsDefaultChecked,
      activePaymentId,
    });
  };

  return (
    <>
      <StepHeader index={index} dataHook={PaymentStepDataHook.header} label={localeKeys.checkout.payment()} />
      <PaymentWidgetWrapper stepState={stepState} country={contactCountry} />
      {stepState === StepState.COLLAPSED && shouldShowBillingForm && (
        <>
          <BillingAddressTitle />
          <ContactAndAddressSummary contact={checkout.billingInfo?.contact} address={checkout.billingInfo?.address} />
        </>
      )}
      {stepState === StepState.OPEN && (
        <>
          {shouldShowBillingForm && <BillingDetails />}
          <DetailsFormButtons
            onContinue={() => void validateAndSubmit()}
            continueButtonDisabled={isContinueButtonDisabled}
            continueButtonState={continueButtonState}
          />
        </>
      )}
    </>
  );
};

const WrappedInternalPaymentStep = withMemberDetailsData(
  withBillingData(InternalPaymentStep),
  (checkout: CheckoutModel) => checkout.billingInfo,
  'validateBillingAddress'
);

export const PaymentStep = ({index}: StepImplementationProps) => {
  return <DynamicStep index={index!} dataHook={PaymentStepDataHook.root} component={WrappedInternalPaymentStep} />;
};
